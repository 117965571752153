import { useTranslation } from 'react-i18next';

import MinerallTodaySkeleton from 'components/skeleton/minerall.today.skeleton';

import { TableRow, TableCell } from '../table-elements';

import {
  StyledTableBody,
  StyledTableColumnTitle,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell
} from '../table-block.style';

export const MinerallTableHead = ({ columns, row, activeCoin }) => {
  const { t } = useTranslation();

  return (
    <StyledTableHead>
      <StyledTableRow columns={5} className="mt">
        {columns.map(({ title, dataIndex }) => (
          <StyledTableCell key={dataIndex}>
            <StyledTableColumnTitle>{t(title)}</StyledTableColumnTitle>
            <TableCell
              item={row}
              dataIndex={dataIndex}
              activeCoin={activeCoin}
              isHead
            />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </StyledTableHead>
  );
};

export const MinerallTable = ({ columns, rows, activeCoin, detailsIsOpen }) => {
  if (!rows) return <MinerallTodaySkeleton />;

  return (
    detailsIsOpen && (
      <StyledTableBody>
        {rows.map((row) => (
          <TableRow
            cssClass="mt"
            key={row.dateTime}
            columns={columns}
            row={row}
            activeCoin={activeCoin}
          />
        ))}
      </StyledTableBody>
    )
  );
};
