const TotalBlocksIcon = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5508 37.2412V53.9998L23.275 45.6206V29.793L19.5508 37.2412Z"
        fill="#466BE6"
      />
      <path
        d="M23.2757 29.793H11.1722H6.51707L2.79297 37.2412H19.5515L23.2757 29.793Z"
        fill="#96AEFF"
      />
      <path
        d="M19.5516 37.2422H2.79297V54.0009H19.5516V37.2422Z"
        fill="#6487FF"
      />
      <path
        d="M51.2074 29.793H39.104H34.4488L30.7246 37.2412H47.4833L51.2074 29.793Z"
        fill="#96AEFF"
      />
      <path
        d="M47.4824 37.2412V53.9998L51.2065 45.6206V29.793L47.4824 37.2412Z"
        fill="#466BE6"
      />
      <path
        d="M30.7246 37.2422V45.6216V54.0009H47.4833V37.2422H30.7246Z"
        fill="#6487FF"
      />
      <path
        d="M19.5508 7.44831V24.2069L23.275 15.8276V0L19.5508 7.44831Z"
        fill="#466BE6"
      />
      <path
        d="M23.2757 0H6.51707L2.79297 7.44831H19.5515L23.2757 0Z"
        fill="#96AEFF"
      />
      <path
        d="M2.79297 7.44922V24.2078H11.1722H19.5515V7.44922H2.79297Z"
        fill="#6487FF"
      />
      <path
        d="M47.4824 7.44831V24.2069L51.2065 15.8276V0L47.4824 7.44831Z"
        fill="#466BE6"
      />
      <path
        d="M51.2074 0H34.4488L30.7246 7.44831H47.4833L51.2074 0Z"
        fill="#96AEFF"
      />
      <path
        d="M30.7246 7.44922V15.8285V24.2078H39.104H47.4833V7.44922H30.7246Z"
        fill="#6487FF"
      />
      <path
        d="M12.1042 24.207H10.2422V46.5519H12.1042V24.207Z"
        fill="#466BE7"
      />
      <path
        d="M40.0359 14.8984H38.1738V37.2433H40.0359V14.8984Z"
        fill="#466BE7"
      />
      <path
        d="M30.725 14.8984H10.2422V16.7605H30.725V14.8984Z"
        fill="#466BE7"
      />
      <path
        d="M40.0355 44.6914H19.5527V46.5535H40.0355V44.6914Z"
        fill="#466BE7"
      />
    </svg>
  );
};

export default TotalBlocksIcon;
