const PoolFanIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2005_3322)">
        <path
          d="M55.1711 60H4.82895C2.16199 60 0 57.838 0 55.1711V4.82895C0 2.16199 2.16199 0 4.82895 0H55.1711C57.838 0 60 2.16199 60 4.82895V55.1711C60 57.838 57.838 60 55.1711 60Z"
          fill="#6487FF"
        />
        <path
          d="M55.1705 0H51.5488C54.2158 0 56.3778 2.16199 56.3778 4.82895V55.1711C56.3778 57.838 54.2158 60 51.5488 60H55.1705C57.8375 60 59.9994 57.838 59.9994 55.1711V4.82895C59.9994 2.16199 57.8375 0 55.1705 0Z"
          fill="#466BE7"
        />
        <path
          d="M29.9996 53.5422C43.0007 53.5422 53.5402 43.0027 53.5402 30.0016C53.5402 17.0004 43.0007 6.46094 29.9996 6.46094C16.9985 6.46094 6.45898 17.0004 6.45898 30.0016C6.45898 43.0027 16.9985 53.5422 29.9996 53.5422Z"
          fill="#F0F4FF"
        />
        <path
          d="M34.6562 13.435C34.6562 13.435 33.6776 29.9982 30.0069 29.9982C26.3363 29.9982 25.2715 13.435 25.2715 13.435C25.2715 10.9812 27.3725 8.99219 29.9646 8.99219C32.555 8.99207 34.6562 10.9812 34.6562 13.435Z"
          fill="#6487FF"
        />
        <path
          d="M13.3387 34.2528C13.3387 34.2528 28.1722 26.8188 30.0075 29.9976C31.8428 33.1764 18.0312 42.3801 18.0312 42.3801C15.9061 43.6071 13.133 42.7819 11.837 40.5372C10.5417 38.2938 11.2138 35.4798 13.3387 34.2528Z"
          fill="#6487FF"
        />
        <path
          d="M42.0268 42.3078C42.0268 42.3078 28.1719 33.1787 30.0073 29.9999C31.8427 26.821 46.7192 34.1805 46.7192 34.1805C48.8443 35.4075 49.5162 38.2215 48.2203 40.4662C46.925 42.7097 44.1519 43.5348 42.0268 42.3078Z"
          fill="#6487FF"
        />
        <path
          d="M5.61363 7.36398C6.61376 7.36398 7.42453 6.55322 7.42453 5.55309C7.42453 4.55295 6.61376 3.74219 5.61363 3.74219C4.6135 3.74219 3.80273 4.55295 3.80273 5.55309C3.80273 6.55322 4.6135 7.36398 5.61363 7.36398Z"
          fill="#466BE7"
        />
        <path
          d="M54.3871 7.36398C55.3872 7.36398 56.198 6.55322 56.198 5.55309C56.198 4.55295 55.3872 3.74219 54.3871 3.74219C53.3869 3.74219 52.5762 4.55295 52.5762 5.55309C52.5762 6.55322 53.3869 7.36398 54.3871 7.36398Z"
          fill="#466BE7"
        />
        <path
          d="M5.61363 56.2585C6.61376 56.2585 7.42453 55.4477 7.42453 54.4476C7.42453 53.4475 6.61376 52.6367 5.61363 52.6367C4.6135 52.6367 3.80273 53.4475 3.80273 54.4476C3.80273 55.4477 4.6135 56.2585 5.61363 56.2585Z"
          fill="#466BE7"
        />
        <path
          d="M54.3871 56.2585C55.3872 56.2585 56.198 55.4477 56.198 54.4476C56.198 53.4475 55.3872 52.6367 54.3871 52.6367C53.3869 52.6367 52.5762 53.4475 52.5762 54.4476C52.5762 55.4477 53.3869 56.2585 54.3871 56.2585Z"
          fill="#466BE7"
        />
        <path
          d="M29.9992 34.8305C32.6664 34.8305 34.8285 32.6683 34.8285 30.0012C34.8285 27.334 32.6664 25.1719 29.9992 25.1719C27.3321 25.1719 25.1699 27.334 25.1699 30.0012C25.1699 32.6683 27.3321 34.8305 29.9992 34.8305Z"
          fill="#466BE7"
        />
        <path
          d="M30.0002 5.57812C16.5349 5.57812 5.58008 16.533 5.58008 29.9984C5.58008 43.4637 16.5349 54.4186 30.0002 54.4186C43.4655 54.4186 54.4203 43.4637 54.4203 29.9984C54.4203 16.533 43.4655 5.57812 30.0002 5.57812ZM52.6439 29.1195H47.1546C46.7102 20.3462 39.6523 13.2882 30.8791 12.844V7.35457C42.6774 7.80703 52.1915 17.3211 52.6439 29.1195ZM29.1213 30.8773V38.1259C25.3141 37.717 22.2816 34.6845 21.8727 30.8773H29.1213ZM21.8727 29.1195C22.2815 25.3123 25.3141 22.2798 29.1213 21.8708V29.1195H21.8727ZM30.8791 30.8773H38.1277C37.7189 34.6845 34.6863 37.7169 30.8791 38.1259V30.8773ZM30.8791 29.1195V21.8708C34.6863 22.2797 37.7188 25.3123 38.1277 29.1195H30.8791ZM29.1213 20.1062C24.3444 20.5273 20.5291 24.3425 20.108 29.1195H14.607C15.0478 21.3159 21.3178 15.0459 29.1214 14.6051L29.1213 20.1062ZM20.108 30.8773C20.5291 35.6543 24.3444 39.4695 29.1213 39.8905V45.3915C21.3177 44.9508 15.0477 38.6807 14.6069 30.8771L20.108 30.8773ZM30.8791 39.8905C35.656 39.4695 39.4713 35.6542 39.8923 30.8773H45.3934C44.9526 38.6808 38.6826 44.9508 30.879 45.3916L30.8791 39.8905ZM39.8923 29.1195C39.4713 24.3424 35.656 20.5273 30.8791 20.1062V14.6052C38.6827 15.0459 44.9527 21.3161 45.3935 29.1196L39.8923 29.1195ZM29.1213 7.35457V12.8439C20.348 13.2882 13.2901 20.3461 12.8458 29.1193H7.35652C7.80887 17.3211 17.323 7.80703 29.1213 7.35457ZM7.35652 30.8773H12.8458C13.2902 39.6505 20.348 46.7085 29.1213 47.1527V52.642C17.323 52.1897 7.80887 42.6756 7.35652 30.8773ZM30.8791 52.6421V47.1529C39.6523 46.7085 46.7103 39.6506 47.1546 30.8774H52.6439C52.1915 42.6756 42.6774 52.1897 30.8791 52.6421Z"
          fill="#466BE7"
        />
      </g>
      <defs>
        <clipPath id="clip0_2005_3322">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PoolFanIcon;
