import { useEffect, useState } from 'react';

export default function useLoadMorePaginate(
  fetchMore,
  activeCoin,
  pageSize = 7
) {
  const [firstLoadMore, setFirstLoadMore] = useState(false);
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [hasMoreValues, setHasMoreValues] = useState(true);
  const [loadingMore, toggleLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (activeCoin?.id) {
      hideDetails();
    }
  }, [activeCoin?.id]);

  const loadMore = async () => {
    toggleLoading(true);
    setFirstLoadMore(true);
    setPageNumber((p) => p + 1);

    fetchMore({
      variables: {
        pageNumber,
        pageSize
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        toggleLoading(false);
        if (!firstLoadMore) return { blocks: [...prev?.blocks] };
        if (!fetchMoreResult) return prev;
        if (fetchMoreResult?.blocks.length === 0) {
          setHasMoreValues(false);
          return prev;
        }

        return {
          ...prev,
          blocks: [...prev?.blocks, ...fetchMoreResult?.blocks]
        };
      }
    });
  };

  const hideDetails = () => {
    fetchMore({
      variables: {
        coinId: activeCoin?.id,
        pageNumber: 1,
        pageSize
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          blocks: [...fetchMoreResult?.blocks]
        };
      }
    });
    setDetailsIsOpen(false);
    setHasMoreValues(true);
    setPageNumber(1);
    setFirstLoadMore(false);
  };

  const showDetails = async (e) => {
    e.preventDefault();
    if (!detailsIsOpen) {
      setDetailsIsOpen(true);
    }

    if (!hasMoreValues) {
      hideDetails();
    }

    if (hasMoreValues) {
      await loadMore();
    }
  };

  return {
    detailsIsOpen,
    hasMoreValues,
    loadingMore,
    showDetails,
    hideDetails
  };
}
