export const PhoenixMiner = ({ name, region, port, currency }) => (
  <>
    <h3>{name}</h3>
    <div>
      <strong>
        <span>Windows (.bat)</span>
      </strong>
      <p>
        PhoenixMiner.exe -pool {region}.minerall.io:{port} -wal WALLETNAME
        -worker WORKERNAME -epsw x -coin {currency.toLowerCase()} -proto 4
      </p>
    </div>
    <div>
      <strong>
        <span>Linux (HiveOS, RaveOS, MinerOS)</span>
      </strong>
      <p>
        POOL: {region}.minerall.io:{port}, WALLET: WALLETNAME.WORKERNAME, PSW:
        x, PROTO: 4, COIN: {currency}
      </p>
    </div>
  </>
);

export const TeamRedMiner = ({ name, region, port, currency }) => (
  <>
    <h3>{name}</h3>
    <div>
      <strong>
        <span>Windows (.bat)</span>
      </strong>
      <p>
        teamredminer.exe -a {currency === 'ETH' ? 'ethash' : 'etchash'} -o
        stratum+tcp://{region}.minerall.io:{port}
        -u WALLETNAME.WORKERNAME -p x
      </p>
    </div>
    <div>
      <strong>
        <span>Linux (HiveOS, RaveOS, MinerOS)</span>
      </strong>
      <p>
        Url stratum+tcp://{region}.minerall.io:{port}
      </p>
      <p>Algo {currency === 'ETH' ? 'ethash' : 'etchash'}</p>
      <p>Pass x</p>
      <p>Template WALLETNAME.WORKERNAME</p>
    </div>
  </>
);

export const NanoMiner = ({ name, region, port, currency }) => (
  <>
    <h3>{name}</h3>
    <div>
      <strong>
        <span>Windows (config.conf)</span>
      </strong>
      <p>{currency === 'ETH' ? '[Ethash]' : '[Etchash]'}</p>
      <p>wallet = WALLETNAME</p>
      <p>coin={currency}</p>
      <p>rigName = WORKERNAME</p>
      <p>
        pool1 = {region}.minerall.io:{port}
      </p>
      <p>protocol = stratum</p>
    </div>
    <div>
      <strong>
        <span>Linux (HiveOS, RaveOS, MinerOS)</span>
      </strong>
      <p>
        Url {region}.minerall.io:{port}
      </p>
      <p>Algo {currency === 'ETH' ? 'ethash' : 'etchash'}</p>
      <p>Pass x</p>
      <p>Template WALLETNAME.WORKERNAME</p>
      <p>User Config protocol=stratum</p>
    </div>
  </>
);

export const GMiner = ({ name, region, port, currency }) => (
  <>
    <h3>{name}</h3>
    <div>
      <strong>
        <span>Windows (.bat)</span>
      </strong>
      <p>
        miner.exe --algo {currency === 'ETH' ? 'ethash' : 'etchash'} --server{' '}
        {region}.minerall.io:{port} --user WALLETNAME --proto stratum
      </p>
    </div>
    <div>
      <strong>
        <span>Linux (HiveOS, RaveOS, MinerOS)</span>
      </strong>
      <p>Algo {currency === 'ETH' ? 'ethash' : 'etchash'}</p>
      <p>Pass x</p>
      <p>Port {port}</p>
      <p>Server {region}.minerall.io</p>
      <p>Template WALLETNAME.WORKERNAME</p>
      <p>User Config --proto stratum</p>
    </div>
  </>
);

export const LoLMiner = ({ name, region, port, currency }) => (
  <>
    <h3>{name}</h3>
    <div>
      <strong>
        <span>Windows (.bat)</span>
      </strong>
      <p>
        set "POOL={region}.minerall.io:{port}"
      </p>
      <p>set "WALLET=WALLETNAME.WORKERNAME"</p>
      <p>
        lolMiner.exe --algo {currency === 'ETH' ? 'ETHASH' : 'ETCHASH'} --pool
        !POOL! --user !WALLET! --ethstratum=ETHV1
      </p>
      <p>timeout 10</p>
    </div>
    <div>
      <strong>
        <span>Linux (HiveOS, RaveOS, MinerOS)</span>
      </strong>
      <p>Algo {currency === 'ETH' ? 'ETHASH' : 'ETCHASH'}</p>
      <p>Pass x</p>
      <p>Port {port}</p>
      <p>Server {region}.minerall.io</p>
      <p>Template WALLETNAME.WORKERNAME</p>
      <p>User Config --ethstratum=ETHV1</p>
    </div>
  </>
);

export const NBMiner = ({ name, region, port, currency }) => (
  <>
    <h3>{name}</h3>
    <div>
      <strong>
        <span>Windows (.bat)</span>
      </strong>
      <p>
        nbminer -a {currency === 'ETH' ? 'ethash' : 'etchash'} -o
        nicehash+tcp://
        {region}
        .minerall.io:{port} -u WALLETNAME.WORKERNAME -log
      </p>
    </div>
    <div>
      <strong>
        <span>Linux (HiveOS, RaveOS, MinerOS)</span>
      </strong>
      <p>
        Url ethnh+tcp://{region}
        .minerall.io:{port}
      </p>
      <p>Algo {currency === 'ETH' ? 'ethash' : 'etchash'}</p>
      <p>Pass x</p>
      <p>Template WALLETNAME.WORKERNAME</p>
    </div>
  </>
);

export const TrexMiner = ({ name, region, port, currency }) => (
  <>
    <h3>{name}</h3>
    <div>
      <strong>
        <span>Windows (.bat)</span>
      </strong>
      <p>
        t-rex.exe -a {currency === 'ETH' ? 'ethash' : 'etchash'} -o
        stratum2+tcp://{region}
        .minerall.io:{port} -u WALLETNAME -p x -w WORKERNAME
      </p>
    </div>
    <div>
      <strong>
        <span>Linux (HiveOS, RaveOS, MinerOS)</span>
      </strong>
      <p>
        Url stratum2+tcp://
        {region}.minerall.io:{port}
      </p>
      <p>Algo {currency === 'ETH' ? 'ethash' : 'etchash'}</p>
      <p>Pass x</p>
      <p>Template WALLETNAME.WORKERNAME</p>
    </div>
  </>
);
