import { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GET_TODAY_BLOCKS } from 'graphql/anonymous.query';

import { useCoins } from 'contexts/coins.context';
import { useWindowSize } from 'utils/hooks/use-window-size';
import { columnsMinerallToday } from 'utils/columns-table';
import { useScrollbar } from 'utils/hooks/use-scrollbar';
import useLoadMorePaginate from 'utils/hooks/use-load-more-paginate';
import LoadMoreButton from '../load-more-button/load-more-button';
import MinerallTableHeader from './minerall-today-header/minerall-today-header';

import { MinerallTable, MinerallTableHead } from './minerall-today-elements';

import {
  StyledEmpty,
  StyledOverflow,
  StyledTable,
  StyledTableScroll,
  StyledTableWrapper
} from '../table-block.style';

const MinerallToday = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { loading, coins } = useCoins();
  const [activeCoin, setActiveCoin] = useState(null);
  const blocksEndRef = useRef(null);
  const overflowWrapperRef = useRef(null);
  const pageSize = 7;

  useScrollbar(overflowWrapperRef, isMobile);

  useEffect(() => {
    if (!loading && coins.length > 0) {
      setActiveCoin(coins[0]);
    }
  }, [coins, loading]);

  const [getTodayBlocks, { data, fetchMore }] = useLazyQuery(GET_TODAY_BLOCKS, {
    fetchPolicy: 'network-only'
  });

  const args = useLoadMorePaginate(fetchMore, activeCoin, pageSize);

  useEffect(() => {
    if (activeCoin?.id) {
      getTodayBlocks({
        variables: {
          coinId: activeCoin.id,
          pageNumber: 1,
          pageSize
        }
      });
    }
  }, [activeCoin?.id, getTodayBlocks]);

  const scrollToBottom = () => {
    blocksEndRef.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (data?.blocks.length > pageSize) {
      scrollToBottom();
    }
  }, [data?.blocks]);

  return (
    <StyledTableWrapper>
      <MinerallTableHeader state={[activeCoin, setActiveCoin]} />

      {data?.blocks.length === 0 ? (
        <StyledEmpty>
          <p>{t('dailyStatisticBlock.empty')}</p>
        </StyledEmpty>
      ) : (
        <StyledTableScroll ref={blocksEndRef}>
          <StyledOverflow ref={overflowWrapperRef}>
            <StyledTable>
              {data?.blocks.length > 0 && (
                <MinerallTableHead
                  columns={columnsMinerallToday}
                  row={data?.blocks[0]}
                  activeCoin={activeCoin}
                />
              )}
              <MinerallTable
                columns={columnsMinerallToday}
                rows={data?.blocks}
                detailsIsOpen={args.detailsIsOpen}
                activeCoin={activeCoin}
              />
            </StyledTable>
          </StyledOverflow>

          <LoadMoreButton {...args} />
        </StyledTableScroll>
      )}
    </StyledTableWrapper>
  );
};

export default MinerallToday;
