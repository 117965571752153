import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { ACTIVATE_WALLET } from 'graphql/wallet.mutation';

import SEO from 'components/seo';
import PoolTime from 'components/pool-time/pool-time';
import TotalBlocks from 'components/total-blocks/total-blocks';
import CoinListBlock from 'components/coin-list/coin-list';
import MinerStatsForm from 'components/miner-stats-form/miner-stats-form';
import MinerConfig from 'components/miner-config/miner-config';
import EquipmentIncome from 'components/equipment-income/equipment-income';
import PaymentsInfoBlock from 'components/payments-infoblock/payments-infoblock';
import Heading from 'components/common/heading/heading';
import MinerallToday from 'components/table-block/minerall-today/minerall-today';
import { authorizeUserOAuthAPI } from 'utils/api';
import { AUTH_LOGIN_PAGE } from 'site-settings/site-navigation';
import { useUser } from 'contexts/user.context';
import { useLocationQuery } from 'utils/hooks/use-query';

import {
  Container,
  MainContentArea,
  Row,
  Section,
  SectionTitle,
  TitleHolder
} from 'assets/styles/pages.style';

export default function HomePage() {
  const { t } = useTranslation();
  const { setData, isAuthenticated } = useUser();
  const walletActivateToken = useLocationQuery('wallet');
  const referrerCode = useLocationQuery('referral');
  const state = useLocationQuery('state');
  const code = useLocationQuery('code');
  const [activateWallet] = useMutation(ACTIVATE_WALLET);

  useEffect(() => {
    if (referrerCode !== null) {
      localStorage.setItem('referrerCode', referrerCode);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && walletActivateToken !== null) {
      activateWallet({
        variables: {
          input: {
            token: walletActivateToken
          }
        },
        context: { clientName: 'private' }
      });
    }
  }, []);

  useEffect(() => {
    if (
      code !== null &&
      state !== null &&
      state === process.env.GATSBY_PROVIDER_STATE
    ) {
      const referrer = localStorage.getItem('referrerCode');
      const values = {
        code,
        provider: 'google',
        redirect: process.env.GATSBY_SITE_URL
      };

      if (referrer) {
        values.referrerCode = referrer;
      }
      const authorizeUser = async () => {
        const data = await authorizeUserOAuthAPI(values);
        if (data) {
          if (data?.twoFA) {
            navigate(`${AUTH_LOGIN_PAGE}?token=${data?.token}`);
          } else {
            setData(data);
            localStorage.setItem('access_token', data.token);
            window.history.pushState({}, '', '/');
          }
        }
      };
      authorizeUser();
    }
  }, []);

  return (
    <MainContentArea>
      <Container>
        <TitleHolder>
          <h2>{t('pageTitles.home.title')}</h2>
          <div className="justify-blocks">
            <TotalBlocks />
            <PoolTime />
          </div>
        </TitleHolder>
        <Row>
          <SectionTitle>{t('last24Block.titleI18n')}</SectionTitle>
          <CoinListBlock type="secondary" />
        </Row>

        <MinerStatsForm />

        <Row>
          <PaymentsInfoBlock />
        </Row>

        <Row>
          <Section>
            <MinerConfig
              title={t('minersConfigBlock.headTitle')}
              subheading={t('minersConfigBlock.proposalI18n')}
            />
          </Section>
        </Row>

        <Row>
          <Section p={5}>
            <Heading variant="h3" justifyContent="center">
              {t('equipmentIncomeBlock.titleI18n')}
            </Heading>
            <EquipmentIncome />
          </Section>
        </Row>

        <Row px={[0, 0, 4]}>
          <MinerallToday />
        </Row>
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.home.title')} />;
};
