import { useTranslation } from 'react-i18next';

import { useCoins } from 'contexts/coins.context';
import {
  bigIntToFloat,
  getPrice,
  handleParseNumber,
  toFixed
} from 'utils/helpers/converters';

import ETHIcon from 'components/icons/ETHIcon';
import ETCIcon from 'components/icons/ETCIcon';

import ETCIconNoShadow from 'components/icons/ETCIconNoShadow';

import {
  CoinDataItem,
  CoinDataList,
  CoinIconName,
  CoinItem,
  CoinName,
  CoinSection,
  CoinSubTitle,
  CoinValue
} from './coin-list.style';

const getComponentIcon = (coin, style) => {
  switch (coin) {
    case 'eth':
      return <ETHIcon />;
    case 'etc':
      if (style === 'secondary') {
        return <ETCIconNoShadow />;
      }

      return <ETCIcon />;
    default:
      return <p>No component match</p>;
  }
};

const CoinListItem = ({
  item,
  type = 'default',
  active = false,
  prop = 'active'
}) => {
  const { t } = useTranslation();
  const { coins, setActiveCoin } = useCoins();
  const coin = coins.find((c) => c.id === item?.coinId);
  const parseNumber = handleParseNumber(item?.hashRate?.[prop]);

  if (!coin) return null;

  return (
    <CoinItem isActive={active} onClick={() => setActiveCoin(coin)}>
      <CoinSection>
        <CoinIconName>
          {getComponentIcon(coin?.tag)}
          <CoinName>{coin?.name}</CoinName>
        </CoinIconName>
      </CoinSection>

      <CoinSection>
        <CoinDataList>
          <CoinDataItem>
            <CoinSubTitle>{t('currencyStatisticsCard.lucki18n')}</CoinSubTitle>
            <CoinValue>{Math.floor(item?.luck?.[prop] * 100) || 0}%</CoinValue>
          </CoinDataItem>

          <CoinDataItem>
            <CoinSubTitle>
              $ {t('currencyStatisticsCard.minedi18n')}
            </CoinSubTitle>
            <CoinValue>
              {getPrice(bigIntToFloat(item?.reward?.[prop]), item?.prices)}
            </CoinValue>
          </CoinDataItem>

          <CoinDataItem>
            <CoinSubTitle>
              {coin?.tag} {t('currencyStatisticsCard.minedi18n')}
            </CoinSubTitle>
            <CoinValue>
              {toFixed(bigIntToFloat(item?.reward?.[prop]))}
            </CoinValue>
          </CoinDataItem>

          <CoinDataItem>
            <CoinSubTitle>{t('currencyStatisticsCard.ratei18n')}</CoinSubTitle>
            <CoinValue>
              {parseNumber?.val} <span>{parseNumber?.metric}</span>
            </CoinValue>
          </CoinDataItem>
        </CoinDataList>
      </CoinSection>
    </CoinItem>
  );
};

export default CoinListItem;
