import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

const MinerallTodaySkeleton = ({ loading = false }) => {
  const theme = useTheme();
  return (
    <ContentLoader
      animate={loading}
      speed={1}
      viewBox="0 0 1200 120"
      backgroundColor={theme?.colors?.skeleton?.backgroundColor}
      foregroundColor={theme?.colors?.skeleton?.foregroundColor}
    >
      <rect x="0" y="0" rx="8" ry="8" width="1200" height="120" />
    </ContentLoader>
  );
};

export default MinerallTodaySkeleton;
