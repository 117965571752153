const ETHIcon = ({ width = 129, height = 188 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 129 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2005_12844)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.4948 0.000207346C64.4948 0.000325451 64.4946 0.000325741 64.4945 0.000207877L64.4945 0.000118615C64.4944 -6.42092e-05 64.4941 -2.85377e-05 64.494 0.000176449L64.4933 0.00269576L64.4931 0.00302394L24.2874 66.7142C23.7138 67.6659 24.026 68.9028 24.9826 69.4682L64.4934 92.8233L64.4936 92.8234L64.4941 92.8239L64.4943 92.8239H64.4945L64.4946 92.824C64.4949 92.8241 64.4952 92.824 64.4952 92.8236L64.4954 92.8233L104.007 69.4682C104.963 68.9028 105.276 67.6659 104.702 66.7142L64.4952 0.00139143L64.4952 0.00117534V0.000301489C64.4952 0.000116018 64.4949 4.8332e-05 64.4948 0.000207346ZM64.4949 139.999C64.4949 139.998 64.496 139.998 64.4963 139.999L64.4965 139.999C64.4967 140 64.4971 140 64.4974 140C64.4977 140 64.4979 140 64.498 140L100.338 89.5257C101.588 87.766 99.5489 85.5483 97.6905 86.6456L64.4973 106.244L64.4969 106.245L64.4963 106.245C64.4958 106.246 64.4949 106.246 64.4949 106.245C64.4949 106.245 64.4947 106.244 64.4945 106.244L31.3245 86.6475C29.4664 85.5497 27.4272 87.7668 28.6762 89.5268L64.4935 139.999C64.4938 140 64.4942 140 64.4945 140C64.4947 140 64.4949 139.999 64.4949 139.999Z"
          fill="#6487FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2005_12844"
          x="0"
          y="0"
          width="128.99"
          height="188"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.392157 0 0 0 0 0.529412 0 0 0 0 1 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2005_12844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2005_12844"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ETHIcon;
