import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { GET_COINS_BLOCKS_TOTAL } from 'graphql/coins.query';

import TotalBlocksIcon from 'components/icons/TotalBlocksIcon';
import { useCoins } from 'contexts/coins.context';

import { Text, TextContainer, TotalBlock } from './total-blocks.style';

const TotalBlocks = () => {
  const { t } = useTranslation();
  const { coins } = useCoins();
  const { data } = useQuery(GET_COINS_BLOCKS_TOTAL);

  if (!data?.coinsInfo) return null;

  return (
    <TotalBlock>
      <TotalBlocksIcon />
      <TextContainer>
        <Text>{t('totalBlocks.totalBlocksTitle')}</Text>
        <Text as="b">
          {data?.coinsInfo.map((item) => {
            const coin = coins.find((c) => c.id === item?.coinId);

            if (coin) {
              return (
                <span key={item?.coinId}>
                  {coin?.tag.toUpperCase()} {item?.poolBlocksTotal}
                </span>
              );
            }
          })}
        </Text>
      </TextContainer>
    </TotalBlock>
  );
};

export default TotalBlocks;
