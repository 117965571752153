import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const PoolTimeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  width: 305px;
  min-height: 80px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    width: auto;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  b {
    text-transform: uppercase;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: ${themeGet('fontSizes.xs')}px;

  .caption {
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;
