import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { zoomInBottom } from 'utils/motion/zoom-in-bottom';
import { Button } from 'components/common/button/button';
import { StyledConfig, StyledSettings } from './miner-config.style';

const ConfigSettings = ({ show, component }) => {
  const { t } = useTranslation();
  const [open, setOpen] = show;

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial="from"
          animate="to"
          exit="from"
          variants={zoomInBottom()}
        >
          <StyledConfig>
            <StyledSettings>
              {component}
              <div className="name-info">
                <p>
                  <span>*WALLETNAME</span> -{' '}
                  {t('minersConfigBlock.walletNameTextI18n')}{' '}
                </p>
                <p>
                  <span>*WORKERNAME</span> -{' '}
                  {t('minersConfigBlock.workerNameTextI18n')}
                </p>
              </div>
            </StyledSettings>
            <Button
              onClick={() => setOpen(false)}
              variant="secondary"
              fullwidth
            >
              {t('minersConfigBlock.closeButton')}
            </Button>
          </StyledConfig>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ConfigSettings;
