import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledMinerFormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    > div > .small {
      margin-left: 20px;
    }
  }
`;

export const StyledFormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &:not(:last-child) {
    margin-bottom: 60px;
  }
`;

export const StyledWrapper = styled.div`
  padding: 35px 40px 40px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  @media ${themeGet('mediaQueries.mobileOnly')} {
    padding: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 60px;
  }
`;

export const StyledConfig = styled.div`
  h3 {
    display: none;
  }
`;

export const StyledSettings = styled.div`
  &:not(:last-child) {
    margin-bottom: 50px;
  }

  > h3 {
    &:not(:last-child) {
      margin-bottom: 40px;

      @media ${themeGet('mediaQueries.mobileOnly')} {
        font-size: ${themeGet('fontSizes.mdUp')}px;
        margin-bottom: 20px;
      }
    }
  }

  > div {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .name-info {
    p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  strong {
    display: block;
    font-weight: ${themeGet('fontWeights.bold')};
  }

  span {
    color: #41c787;
  }
`;

export const StyledToggler = styled.div`
  &:not(:last-child) {
    margin-bottom: 60px;
  }
`;
