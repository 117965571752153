import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const TotalBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  width: 305px;
  min-height: 80px;

  @media ${themeGet('mediaQueries.mobileOnly')} {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  b {
    text-transform: uppercase;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const Text = styled.span`
  margin: 0;
  font-size: ${themeGet('fontSizes.xs')}px;

  span {
    &:not(:first-child) {
      margin-left: 7px;
    }
  }

  b {
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;
