import { useQuery } from '@apollo/client';

import { useCoins } from 'contexts/coins.context';
import { GET_COINS_INFO } from 'graphql/coins.query';
import CoinListItem from './coin-list-item';
import { CoinWrapper, CoinList } from './coin-list.style';

const CoinListBlock = ({ type = 'default' }) => {
  const { activeCoin } = useCoins();
  const { data, loading } = useQuery(GET_COINS_INFO);

  if (loading || !data?.coinsInfo) return null;

  return (
    <CoinWrapper>
      <CoinList className={`style-${type}`}>
        {data?.coinsInfo.map((coin) => (
          <CoinListItem
            key={coin.coinId}
            type={type}
            active={activeCoin?.id === coin.coinId}
            prop="day"
            item={coin}
          />
        ))}
      </CoinList>
    </CoinWrapper>
  );
};

export default CoinListBlock;
