import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { useCoins } from 'contexts/coins.context';
import {
  optionsRegions,
  minerSettings,
  minerConfig,
  getPort
} from 'utils/constant';
import Dropdown from 'components/common/form/dropdown/dropdown';
import Heading from 'components/common/heading/heading';
import MinerForm from './miner-form';
import MinerToggler from './miner-toggler';
import ConfigSettings from './config-settings';

import {
  PhoenixMiner,
  TeamRedMiner,
  NanoMiner,
  GMiner,
  LoLMiner,
  NBMiner,
  TrexMiner
} from './settings';

import {
  StyledMinerFormWrapper,
  StyledWrapper,
  StyledSettings
} from './miner-config.style';

const getComponent = (values) => {
  switch (values.team) {
    case 'PhoenixMiner':
      return <PhoenixMiner {...values} />;
    case 'TeamRedMiner':
      return <TeamRedMiner {...values} />;
    case 'NanoMiner':
      return <NanoMiner {...values} />;
    case 'GMiner':
      return <GMiner {...values} />;
    case 'LoLMiner':
      return <LoLMiner {...values} />;
    case 'NBMiner':
      return <NBMiner {...values} />;
    case 'TrexMiner':
      return <TrexMiner {...values} />;
    default:
      return <p>No component match</p>;
  }
};

const MinerConfig = ({
  title,
  subheading,
  mode = 'default',
  justifyContent = 'space-between'
}) => {
  const { t } = useTranslation();

  const { coins, loading } = useCoins();
  const [activeCoin, setActiveCoin] = useState(null);
  const [isShowSetting, setIsShowSetting] = useState(false);
  const [values, setValues] = useState(minerConfig);
  const [region, setRegion] = useState(minerConfig.region);

  useEffect(() => {
    if (!loading && coins.length > 0) {
      setActiveCoin(coins[0]);
    }
  }, [coins, loading]);

  useEffect(() => {
    if (activeCoin !== null) {
      setValues((prev) => ({
        ...prev,
        currency: activeCoin?.tag.toUpperCase(),
        port: getPort(activeCoin?.tag, prev.region)
      }));
    }
  }, [activeCoin]);

  const dropdownChange = ({ target }) => {
    const { value } = target;
    setRegion(value);
    setValues((prev) => ({
      ...prev,
      region: value,
      port: getPort(prev.currency, value)
    }));
  };

  const handleSubmit = () => {
    setIsShowSetting(true);
  };

  return (
    <StyledMinerFormWrapper>
      {title && (
        <Heading
          variant="h3"
          justifyContent={justifyContent}
          subheading={subheading}
          component={
            <Dropdown
              name="region"
              variant="small"
              value={region}
              onChange={dropdownChange}
              options={optionsRegions}
            />
          }
        >
          {title}
        </Heading>
      )}

      {mode === 'default' && (
        <>
          <MinerForm
            region={region}
            state={[values, setValues]}
            onSubmit={handleSubmit}
          />
          <ConfigSettings
            component={getComponent(values)}
            show={[isShowSetting, setIsShowSetting]}
          />
        </>
      )}

      {mode === 'toggler' && (
        <>
          {/* <MinerToggler state={[activeCoin, setActiveCoin]} /> */}
          {minerSettings.map(({ value, label }) => (
            <StyledWrapper>
              <StyledSettings>
                {getComponent({ ...values, team: value, name: label })}
                <div className="name-info">
                  <p>
                    <span>*WALLETNAME</span> -{' '}
                    {t('minersConfigBlock.walletNameTextI18n')}
                  </p>
                  <p>
                    <span>*WORKERNAME</span> -{' '}
                    {t('minersConfigBlock.workerNameTextI18n')}
                  </p>
                </div>
              </StyledSettings>
            </StyledWrapper>
          ))}
        </>
      )}
    </StyledMinerFormWrapper>
  );
};

export default MinerConfig;
