import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GET_COINS_INFO } from 'graphql/coins.query';
import { handleParseNumber } from 'utils/helpers/converters';
import CoinToggler from 'components/common/coin-toggler/coin-toggler';
import Heading from 'components/common/heading/heading';

import {
  StyledTableColumnTitle,
  StyledWrapper,
  StyledValue
} from './minerall-today-header.style';

const MinerallTableHeader = ({ state }) => {
  const { t } = useTranslation();
  const [activeCoin] = state;
  const { data, loading } = useQuery(GET_COINS_INFO);
  const [activeCoinInfo, setActiveCoinInfo] = useState({
    prices: {
      USD: 0
    },
    hashRate: {
      active: 0
    },
    poolWorkers: 0
  });

  useEffect(() => {
    if (!loading && data?.coinsInfo && activeCoin?.id) {
      setActiveCoinInfo(
        data?.coinsInfo.find((i) => i.coinId === activeCoin?.id)
      );
    }
  }, [activeCoin?.id, data?.coinsInfo, loading]);

  const { val, metric } = handleParseNumber(activeCoinInfo?.hashRate?.active);

  return (
    <StyledWrapper>
      <Heading variant="h3" mb="0">
        {t('minerallTodayBlock.titleI18n')}
      </Heading>

      {/* <div className="coinTogglerWrapper"> */}
      {/*  <CoinToggler state={state} width="100%" /> */}
      {/* </div> */}

      <div className="valueWrapper">
        <StyledTableColumnTitle>
          {t('minerallTodayBlock.workersI18n')}
        </StyledTableColumnTitle>
        <StyledValue>{activeCoinInfo?.poolWorkers}</StyledValue>
      </div>

      <div className="valueWrapper">
        <StyledTableColumnTitle>
          {t('minerallTodayBlock.hashrateI18n')}
        </StyledTableColumnTitle>
        <StyledValue>
          {val} <span className="dimension"> {metric} </span>
        </StyledValue>
      </div>
    </StyledWrapper>
  );
};

export default MinerallTableHeader;
