import { gql } from '@apollo/client';

export const GET_ANONYMOUS_USER = gql`
  query ($address: String!) {
    anonymousUser(address: $address) {
      id
      email
      userName
      balances {
        coinId
        incomes
        expenses
      }
      wallets {
        id
        name
        address
        coinId
        networkType
        active
      }
    }
  }
`;

export const GET_ANONYMOUS_USER_SETTINGS = gql`
  query ($userId: ObjectID!, $coinId: ObjectID!) {
    anonymousUserSettings(userId: $userId, coinId: $coinId) {
      coinId
      reward {
        blocked
        poolFee
        poolFeeMessage
      }
      withdraw {
        networkType
        blocked
        poolFee
        networkFee
        minAmount
        maxAmount
      }
    }
  }
`;

export const GET_ANONYMOUS_USER_INFO = gql`
  query ($userId: ObjectID!, $coinId: ObjectID!) {
    anonymousUserInfo(userId: $userId, coinId: $coinId) {
      coinId
      hashRate
      shares
      sharesOk
      activeWorkers
      inactiveWorkers
      blocks
      reward
      lastShare
    }
  }
`;

export const GET_ANONYMOUS_STATISTICS_BY_DAY = gql`
  query (
    $userId: ObjectID!
    $coinId: ObjectID!
    $fromDate: Time!
    $toDate: Time!
  ) {
    anonymousStatisticsByDay(
      userId: $userId
      coinId: $coinId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      blockCount
      reward
      hashRate
      luck
      prices
      dateTime
    }
  }
`;

export const GET_ANONYMOUS_STATISTICS_BY_HOUR = gql`
  query ($userId: ObjectID!, $coinId: ObjectID!, $date: Time!) {
    anonymousStatisticsByHour(userId: $userId, coinId: $coinId, date: $date) {
      blockCount
      reward
      hashRate
      luck
      prices
      dateTime
    }
  }
`;

export const GET_ANONYMOUS_HASHRATE = gql`
  query ($userId: ObjectID!, $coinId: ObjectID!) {
    anonymousHashRate(userId: $userId, coinId: $coinId) {
      hashRate
      dateTime
    }
  }
`;

export const GET_ANONYMOUS_WORKERS = gql`
  query ($userId: ObjectID!, $coinId: ObjectID!) {
    anonymousWorkers(userId: $userId, coinId: $coinId) {
      workerId
      active
      shares
      sharesOk
      hashRate
      lastShare
    }
  }
`;

export const GET_ANONYMOUS_TRANSACTIONS = gql`
  query (
    $userId: ObjectID!
    $coinId: ObjectID!
    $pageNumber: Uint64!
    $pageSize: Uint64!
  ) {
    anonymousTransactions(
      userId: $userId
      coinId: $coinId
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      coinId
      networkType
      type
      status
      amount
      createdAt
      updatedAt
      networkInfo {
        transactionHash
        fromAddress
        toAddress
      }
      prices
    }
  }
`;

export const GET_ANONYMOUS_TELEGRAM_BOT = gql`
  query ($userId: ObjectID!) {
    userTelegramBot(userId: $userId)
  }
`;

export const GET_TODAY_BLOCKS = gql`
  query ($coinId: ObjectID!, $pageNumber: Uint64!, $pageSize: Uint64!) {
    blocks(coinId: $coinId, pageNumber: $pageNumber, pageSize: $pageSize) {
      coinId
      hash
      number
      type
      rewardType
      rewardAmount
      difficulty
      luck
      time
      prices
    }
  }
`;
