import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  margin-top: ${(props) => (props.detailsIsOpen ? '10px' : '20px')};

  .modal-close {
    position: absolute;
    right: 20px;
    top: 18px;
    padding: 3px;
    background-color: transparent;
    color: ${themeGet('colors.text.regular')};
    transition: all 0.25s ease;

    &:hover {
      color: ${themeGet('colors.text.light')};
    }
  }
`;
