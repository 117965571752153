import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { navigate } from 'gatsby';
import { themeGet } from '@styled-system/theme-get';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { regularCoins } from 'utils/constant';
import { useUser } from 'contexts/user.context';
import { Button } from 'components/common/button/button';
import { Input } from 'components/common/form/input';
import Heading from 'components/common/heading/heading';

import { Row, Section } from 'assets/styles/pages.style';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-direction: row;
  }
`;

const initialValues = {
  wallet_address: ''
};

const MinerStatsForm = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();

  if (isAuthenticated) return null;

  const onSubmitHandler = async (
    values,
    { touched, validateForm, setTouched, setSubmitting }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      setSubmitting(false);
      navigate(`/minerstats/${values.wallet_address}`);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <Row>
      <Section>
        <Heading variant="h3" subheading={t('minerStatsHead.textI18n')}>
          {t('minerStatsHead.titleI18n')}
        </Heading>

        <Formik
          validateOnBlur
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            wallet_address: yup
              .string()
              .required(t('walletsBlock.walletFields.addressRequired'))
              .matches(
                regularCoins.eth,
                t('walletsBlock.walletFields.addressNotValid')
              )
          })}
          onSubmit={onSubmitHandler}
        >
          {({ values, handleChange, handleBlur, touched, errors }) => (
            <Form noValidate>
              <InputWrapper>
                <Input
                  type="text"
                  name="wallet_address"
                  value={values.wallet_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    Boolean(errors.wallet_address) && touched.wallet_address
                  }
                  placeholder={t('minerStatsHead.labelI18n')}
                  required
                />
                <Button disabled={errors.wallet_address} type="submit">
                  {t('minerStatsHead.buttonI18n')}
                </Button>
              </InputWrapper>
            </Form>
          )}
        </Formik>
      </Section>
    </Row>
  );
};

export default MinerStatsForm;
