import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCoins } from 'contexts/coins.context';
import PoolFanIcon from 'components/icons/PoolFanIcon';
import { formatTime, bigIntToFloat } from 'utils/helpers/converters';
import { PoolTimeBlock, TextContainer, Text } from './pool-time.style';

const PoolTime = () => {
  const { t } = useTranslation();
  const { activeCoin } = useCoins();
  const [serverTime, setServerTime] = useState(formatTime(new Date()));

  const payoutTime = `00:00 UTC / ${
    activeCoin
      ? bigIntToFloat(activeCoin?.transaction?.withdraw[0].minAmount)
      : 0.1
  } ${activeCoin?.tag.toUpperCase() || 'ETC'}`;

  useEffect(() => {
    const interval = setInterval(
      () => setServerTime(formatTime(new Date())),
      30000
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <PoolTimeBlock>
      <PoolFanIcon />
      <TextContainer>
        <Text as="b">
          {t('poolTimeBlock.poolTimeI18n')} {serverTime}
        </Text>
        <Text>
          {t('poolTimeBlock.payoutsI18n')}{' '}
          <span className="caption">{payoutTime}</span>
        </Text>
      </TextContainer>
    </PoolTimeBlock>
  );
};

export default PoolTime;
