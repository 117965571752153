import styled from 'styled-components';
import css from '@styled-system/css';
import { themeGet } from '@styled-system/theme-get';

export const PaymentWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 80px;
  }
`;

export const PaymentList = styled.ul(() =>
  css({
    display: 'grid',
    gridGap: '20px',
    listStyle: 'none',
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(2, 1fr)',
      'repeat(2, 1fr)',
      'repeat(4, 1fr)'
    ]
  })
);

export const PaymentSuffix = styled.span`
  margin-left: 7px;
  font-size: ${themeGet('fontSizes.md')}px;
`;

export const PaymentItem = styled.li`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 26px 20px 20px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};
  text-align: center;

  &:first-child {
    ${PaymentSuffix} {
      display: block;
      font-size: ${themeGet('fontSizes.sm')}px;
    }
  }
`;

export const PaymentValue = styled.h2`
  font-size: 54px;
  line-height: 1.4;
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
`;

export const PaymentTitle = styled.h6`
  margin-bottom: 6px;
  text-transform: uppercase;
  color: ${themeGet('colors.primary.regular')};
`;
