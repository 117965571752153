import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'components/icons/CloseIcon';
import { Button } from 'components/common/button/button';
import { ButtonWrapper } from './load-more-button.style';

const LoadMoreButton = ({
  detailsIsOpen,
  hasMoreValues,
  hideDetails,
  showDetails,
  loadingMore
}) => {
  const { t } = useTranslation();
  const closeI18n = t('minerallTodayBlock.closeI18n');
  const moreI18n = t('minerallTodayBlock.moreI18n');
  const detailsI18n = t('minerallTodayBlock.detailsI18n');
  const [buttonTitle, setButtonTitle] = useState(detailsI18n);

  useEffect(() => {
    if (!detailsIsOpen && hasMoreValues) {
      setButtonTitle(detailsI18n);
    }
    if (detailsIsOpen && hasMoreValues) {
      setButtonTitle(moreI18n);
    }
    if (!hasMoreValues) {
      setButtonTitle(closeI18n);
    }
  }, [closeI18n, detailsI18n, detailsIsOpen, hasMoreValues, moreI18n]);

  const closeHandler = (e) => {
    e.stopPropagation();
    hideDetails();
  };

  return (
    <ButtonWrapper detailsIsOpen={detailsIsOpen}>
      <Button
        loading={loadingMore}
        variant="secondary"
        fullwidth
        onClick={showDetails}
      >
        {buttonTitle}
        {detailsIsOpen && hasMoreValues && (
          <button type="button" className="modal-close" onClick={closeHandler}>
            <CloseIcon />
          </button>
        )}
      </Button>
    </ButtonWrapper>
  );
};

export default LoadMoreButton;
