import { useTranslation } from 'react-i18next';

import { bigIntToPercent, bigIntToFloat } from 'utils/helpers/converters';
import { useCoins } from 'contexts/coins.context';

import {
  PaymentWrapper,
  PaymentList,
  PaymentItem,
  PaymentTitle,
  PaymentValue,
  PaymentSuffix
} from './payments-infoblock.style';

const PaymentsInfoBlock = () => {
  const { t } = useTranslation();
  const { activeCoin } = useCoins();

  const paymentType = activeCoin?.mining?.blockRewardType;
  const paymentTypeFee = activeCoin?.transaction?.reward?.poolFee;
  const minPayment = activeCoin?.transaction?.withdraw[0]?.minAmount;
  const transactionFee = activeCoin?.transaction?.withdraw[0]?.networkFee;

  return (
    <PaymentWrapper>
      <PaymentList>
        <PaymentItem>
          <PaymentTitle>{t('paymentsInfoBlock.typeI18n')}</PaymentTitle>
          <PaymentValue>
            {paymentType || 'pplns'}
            <PaymentSuffix>
              {`${t('paymentsInfoBlock.poolFeeI18n')} ${bigIntToPercent(
                paymentTypeFee
              )}%`}
            </PaymentSuffix>
          </PaymentValue>
        </PaymentItem>
        <PaymentItem>
          <PaymentTitle>{t('paymentsInfoBlock.minimumI18n')}</PaymentTitle>
          <PaymentValue>
            {bigIntToFloat(minPayment) || 0.1}
            <PaymentSuffix>{activeCoin?.tag || 'ETH'}</PaymentSuffix>
          </PaymentValue>
        </PaymentItem>
        <PaymentItem>
          <PaymentTitle>{t('paymentsInfoBlock.dailyI18n')}</PaymentTitle>
          <PaymentValue>
            00:00
            <PaymentSuffix>UTC</PaymentSuffix>
          </PaymentValue>
        </PaymentItem>
        <PaymentItem>
          <PaymentTitle>{t('paymentsInfoBlock.feeI18n')}</PaymentTitle>
          <PaymentValue>
            {`${bigIntToFloat(transactionFee) || 0}%`}
          </PaymentValue>
        </PaymentItem>
      </PaymentList>
    </PaymentWrapper>
  );
};

export default PaymentsInfoBlock;
