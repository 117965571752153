import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const CoinWrapper = styled.div``;

export const CoinItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 38px 20px 28px;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};
  border: 2px solid
    ${(props) =>
      props.isActive ? themeGet('colors.primary.regular') : 'transparent'};
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 20px;
    flex-direction: column;
  }
`;

export const CoinSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 10px;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
  }

  &:last-child {
    align-items: flex-start;
  }
`;

export const CoinDataList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const CoinDataItem = styled.li`
  position: relative;

  &:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 16px;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 4px;
      display: block;
      background-color: ${themeGet('colors.primary.light')};
      content: '';
    }
  }
`;

export const CoinName = styled.h4`
  font-size: 30px;
  text-transform: uppercase;
  border-radius: ${themeGet('radii.base')};
  color: ${themeGet('colors.primary.regular')};

  @media (min-width: 992px) {
    text-align: center;
  }
`;

export const CoinIconName = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 991px) {
    flex-direction: row;

    svg {
      margin-right: 10px;
      max-width: 35px;
      height: 80px;
    }
  }
`;

export const CoinList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  &.style-secondary {
    grid-template-columns: 1fr;

    ${CoinItem} {
      padding: 38px 28px;
      justify-content: space-between;
      align-items: center;
    }

    ${CoinSection} {
      @media (max-width: 991px) {
        &:first-child {
          margin-bottom: 40px;
        }
      }
    }

    ${CoinDataList} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 60px;

      @media (max-width: 575px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }
    }

    ${CoinName} {
      font-size: 23px;
    }

    ${CoinDataItem} {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;

export const CoinSubTitle = styled.h5`
  text-transform: uppercase;
  font-size: ${themeGet('fontSizes.sm')}px;
  color: ${themeGet('colors.primary.regular')};
`;

export const CoinValue = styled.h2`
  &:not(:last-child) {
    margin-bottom: 6px;
  }

  span {
    text-transform: uppercase;
    font-size: ${themeGet('fontSizes.md')}px;
  }
`;

export const CoinSubValue = styled.h5`
  margin-bottom: 15px;
  font-size: ${themeGet('fontSizes.xs')}px;
  text-transform: uppercase;

  span {
    color: ${themeGet('colors.primary.regular')};
  }
`;
