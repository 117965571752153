import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledTableColumnTitle = styled.div`
  text-transform: uppercase;
  font-size: ${themeGet('fontSizes.')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.primary.regular')};

  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

export const StyledWrapper = styled.div`
  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: start;
    padding-bottom: 30px;
  }

  @media ${themeGet('mediaQueries.bigDesktopUp')} {
    grid-template-columns: 22% 21% 24% 33%;
    align-items: end;
  }

  .coinTogglerWrapper {
    padding: 20px 0 40px 0px;

    @media ${themeGet('mediaQueries.tabletPortraitUp')} {
      padding: 0 0 20px 20px;
    }

    @media ${themeGet('mediaQueries.bigDesktopUp')} {
      padding: 0 0 0 20px;
      order: 4;
    }
  }

  .valueWrapper {
    padding-bottom: 20px;

    @media ${themeGet('mediaQueries.tabletPortraitUp')} {
      padding-bottom: 0;
      padding-left: 20px;
    }
  }
`;

export const StyledValue = styled.div`
  font-size: ${themeGet('fontSizes.lg')}px;
  font-weight: ${themeGet('fontWeights.heading')};
  line-height: ${themeGet('lineHeights.heading')};
  .dimension {
    font-weight: ${themeGet('fontWeights.regular')};
  }
`;
