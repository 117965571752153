import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { coins, minerSettings, getPort } from 'utils/constant';
import { Button } from 'components/common/button/button';
import { Input } from 'components/common/form/input';
import Dropdown from 'components/common/form/dropdown/dropdown';

import { StyledFormWrapper } from './miner-config.style';

const MINERALL_LINK = '.minerall.io';

const MinerForm = ({ region, state, onSubmit }) => {
  const { t } = useTranslation();
  const [values, setValues] = state;
  const { currency, team } = values;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
      port: name === 'currency' ? getPort(value, region) : prev.port
    }));
  };

  return (
    <StyledFormWrapper>
      <Dropdown
        name="currency"
        value={currency}
        onChange={handleChange}
        options={coins}
      />
      <Dropdown
        name="team"
        value={team}
        onChange={handleChange}
        options={minerSettings}
      />
      <Input
        type="text"
        value={region + MINERALL_LINK}
        variant="outlined"
        readonly
      />
      <Input
        type="text"
        value={getPort(values.currency, region)}
        variant="outlined"
        readonly
      />
      <Button onClick={onSubmit} type="submit">
        {t('minersConfigBlock.buttonGetI18n')}
      </Button>
    </StyledFormWrapper>
  );
};

export default memo(MinerForm);
