const ETCIcon = ({ width = 114, height = 188 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 114 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2005_12877)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.8558 55.853C23.8476 57.6757 25.9495 59.6331 27.6959 58.4979L55.9895 40.1061C56.6564 39.6726 57.5167 39.6755 58.1806 40.1133L86.128 58.5436C87.8721 59.6938 89.9898 57.7361 88.9799 55.9072L58.6793 1.03323C57.9189 -0.343847 55.9398 -0.344553 55.1784 1.03197L24.8558 55.853ZM24.9048 71.3124C23.7007 70.5244 23.6979 68.7608 24.8995 67.9689L56.025 47.4579C56.6943 47.0169 57.5622 47.018 58.2303 47.4608L88.944 67.8151C90.1345 68.6041 90.1382 70.3507 88.9509 71.1447L58.2481 91.6775C57.5813 92.1235 56.7125 92.1278 56.0412 91.6885L24.9048 71.3124ZM24.8491 83.2452C23.8442 81.4191 25.954 79.4698 27.6965 80.6136C37.7548 87.2164 47.7662 93.8032 55.9847 99.2185C56.654 99.6596 57.5211 99.6578 58.1892 99.2149L86.1827 80.6592C87.9214 79.5067 90.0411 81.4537 89.0426 83.2851C78.3547 102.887 68.9844 120.051 58.8372 138.53C58.0786 139.911 56.094 139.914 55.3328 138.535C53.4758 135.168 51.5938 131.757 49.7006 128.326C41.2345 112.981 32.5428 97.2266 24.8491 83.2452Z"
          fill="#6487FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2005_12877"
          x="0"
          y="0"
          width="113.84"
          height="187.566"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.392157 0 0 0 0 0.529412 0 0 0 0 1 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2005_12877"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2005_12877"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ETCIcon;
